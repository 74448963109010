import React from "react"
import { Col, Row } from "react-bootstrap"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { Link } from "gatsby"
import { PaintedFrame } from "../components/painted-frame"

const IndexPage = () => (
  <Layout pageInfo={{ pageName: "index" }}>
    <Seo title="Welkom" />

    <section className="container" id="how-special">
      <div className="section-heading">
        <h2 className="title">Hoe bijzonder zijn tekeningen?</h2>
      </div>

      <Row>
        <Col>
          <div className="block">
            <p>
              Als ouder, of professional die met kinderen werkt, wil je een kind zo goed mogelijk begrijpen. Maar
              kinderen
              kunnen niet altijd alles vertellen, ze zijn daar simpelweg nog te jong voor of ze kunnen niet goed onder
              woorden brengen wat hen bezighoudt. Ook willen ze het soms gewoon helemaal niet vertellen of zij durven
              dit
              niet. In meer extreme gevallen mogen zij niet alles vertellen wat er in hen omgaat. En dat terwijl je
              zeker
              weet dat er iets bij het kind speelt! Dit komt vaker voor dan je denkt. En wat dan? Of misschien weet je
              eigenlijk heel goed waardoor het kind zich ineens anders gedraagt, er is bijvoorbeeld iets heftigs gebeurd
              of jullie zitten in een moeilijke periode. Ook dan wil je waarschijnlijk graag weten wat er in het kind
              omgaat. Nou, dat kan!
            </p>

            <p>
              Naast de gesproken taal bestaat er ook een beeldentaal van tekeningen. Kinderen tekenen overal, niet
              alleen
              met potlood of stift op papier, maar denk ook aan geverfde kunstwerken of een kunstwerk gemaakt door
              middel
              van tekenen in scheerschuim. In deze tekeningen kunnen kinderen hun gevoelens, emoties en gebeurtenissen
              uiten zonder dat het invloed heeft op de echte wereld. Op die manier kunnen spanningen worden ontladen en
              dingen een plekje worden gegeven. Deze tekeningen bevatten dus een schat aan informatie, zelfs de paar
              krassen van een heel jong kind: zij laten onbewust iets zien van zichzelf en vertellen in hun tekeningen
              zonder woorden wat hen bezighoudt of hoe zij zich voelen. Door middel van een tekening kun je in de
              belevingswereld van het kind stappen. Dit gaat het beste bij tekeningen die zijn gemaakt zonder opdracht,
              dus vrije tekeningen waarbij geen ‘hints’ aan het kind zijn gegeven over wat het zou kunnen tekenen of
              hoe.
            </p>

            <p>
              In tekeningen is dus onder meer zichtbaar:
              <ul>
                <li>het verwerken van emoties en gebeurtenissen;</li>
                <li>het spiegelen van de omgeving;</li>
                <li>wat een kind bezighoudt;</li>
                <li>het zelfbeeld;</li>
                <li>hoe het gezin of de omgeving wordt ervaren;</li>
                <li>de ontwikkeling van een kind, etc.</li>
              </ul>
              Wanneer je weet wat er speelt, kun je het kind bieden wat hij of zij écht nodig heeft en van je vraagt. Je
              kunt je beter met het kind verbinden, wat een positieve onderlinge band versterkt.
            </p>

            <p>
              Natuurlijk geldt dit alles niet alleen voor kinderen, ook volwassenen laten vanalles zien in hun
              tekeningen. Niet alleen een kind, ook een volwassene is onbewust met dingen bezig waar de vinger niet goed
              op kan
              worden gelegd of maakt dingen mee die verwerkt moeten worden. Je zult ervan staan te kijken wat er ook uit
              die tekeningen kan worden gehaald!
            </p>

            <p>
              Nieuwsgierig geworden? Kijk <Link to="/hoe-werkt-het">hier</Link> hoe ik werk!
            </p>
          </div>
        </Col>
      </Row>
    </section>

    <section className="container" id="experiences">
      <div className="section-heading">
        <h2 className="title"> Ervaringen</h2>
      </div>

      <Row className="justify-content-md-around">
        <Col sm={{ span: 12 }} lg={{ span: 6 }} xl={{ span: 4 }}>
          <PaintedFrame>
            <blockquote>
              De informatie die Yvonne eruit kon halen was herkenbaar, maar gaf ook weer nieuwe inzichten.
            </blockquote>

            <h2>Floortje</h2>
            <div className="block">
              <div className="margin-top">
                <p>
                  Wij waren positief verrast door de analyse van de huistekening van onze zoon (7 jaar). De informatie
                  die Yvonne eruit kon halen was herkenbaar, maar gaf ook weer nieuwe inzichten in zijn belevingswereld.
                  Verrassend op hoeveel manieren je kunt kijken naar zo’n simpele tekening en dat daar dan informatie
                  uitkomt die echt slaat op jouw kind. De manier waarop Yvonne haar interpretaties beschrijft is heel
                  respectvol en eerlijk. Ze geeft duidelijk weer wat ze observeert en wat dit zou kunnen betekenen,
                  zonder aannames te doen. Al met al vinden wij het een heel leuk inkijkje in de diepere laag achter
                  deze tekening.
                </p>
              </div>
            </div>
          </PaintedFrame>
        </Col>

        <Col sm={{ span: 12 }} lg={{ span: 6 }} xl={{ span: 4 }}>
          <PaintedFrame>
            <blockquote>
              Een goede analyse waar we ook echt iets aan hebben.
            </blockquote>
            <h2>Sanne</h2>
            <div className="block">
              <div className="margin-top">
                <p>
                  Uit de analyse kun je veel herkenbare dingen halen, bijvoorbeeld het feit dat de tekenaar zich in de
                  puberteit bevindt, dat hij zich aan het ontwikkelen is en dat hij steeds meer in balans komt. Zijn
                  relatie met zowel vader als moeder is sterk, maar het is wel een echt moederskindje. Het is een goede
                  analyse waar we ook echt iets aan hebben.
                </p>
              </div>
            </div>
          </PaintedFrame>
        </Col>
      </Row>
    </section>

    <section className="container" id="why-us">
      <div className="section-heading">
        <h2 className="title">Waarom kiezen voor Wondere Wereld van Tekeningen?</h2>
      </div>
      <Row>
        <Col>
          <div className="block">
            <h4>Betrouwbaarheid </h4>
            <p>
              Wat wij afspreken, dat kom ik ook na! En natuurlijk zal alle informatie die ik krijg, zowel van de ouder
              of
              professional als uit de tekening, vertrouwelijk zijn.
            </p>

            <h4>Kwaliteit </h4>
            <p>
              Kwaliteit is voor mij heel belangrijk, ik probeer mijn analyse op een goede manier te doen en de
              informatie
              die ik uit mijn analyse haal ook op een manier terug te koppelen aan de ouder of professional zodat die
              daar
              iets mee kan.
            </p>

            <h4>Snelheid </h4>
            <p>
              Natuurlijk is er tijd nodig om een analyse op een goede manier uit te kunnen voeren: een goede analyse
              neemt
              al snel 4 tot 5 uur in beslag, het is een heel proces dat je moet doorlopen! Maar ik begrijp dat het voor een
              ouder
              of professional niet prettig is lang te moeten wachten wanneer je het gevoel hebt dat er bij een kind iets
              speelt. Ik zal dan ook mijn uiterste best doen om dit proces zo voortvarend mogelijk te laten lopen.
            </p>
          </div>
        </Col>
      </Row>
    </section>
  </Layout>
)

export default IndexPage